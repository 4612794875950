
import VueTypes from 'vue-types';

import PillButton from '../buttons/PillButton.vue';
import articles from '@/gql/queries/pages/articles.gql';

export default {
  components: { PillButton },
  inheritAttrs: false,
  props: {
    heading: VueTypes.string,
    intro: VueTypes.string,
  },
  data() {
    return {
      entries: null,
    };
  },
  async fetch() {
    const { entries } = await this.$cms.query({
      query: articles,
      variables: {
        site: this.$site,
        limit: 9,
      },
    });
    this.entries = entries;
  },
};
