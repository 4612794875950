import { render, staticRenderFns } from "./PatientStories.vue?vue&type=template&id=7c0576c6"
import script from "./PatientStories.vue?vue&type=script&lang=js"
export * from "./PatientStories.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Container: require('/workspace/app/components/layout/Container.vue').default,DefaultCard: require('/workspace/app/components/cards/DefaultCard.vue').default,PillButton: require('/workspace/app/components/buttons/PillButton.vue').default,Module: require('/workspace/app/components/layout/Module.vue').default})
